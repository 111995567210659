import React from "react"
import { Grid } from "@material-ui/core"
import PropTypes from "prop-types"

const defaultContent = (
  <>
    <h1>Psychologische Beratung über die Chatfunktion oder Telefonate</h1>
    <p>
      Du hast Fragen zum Etablieren einer Routine, möchtest konkrete Ziele des
      Trainings gemeinsam ausloten und das Mindance Programm genauer kennen
      lernen? Oder möchtest Du über persönliche Herausforderungen und belastende
      Gefühle und Gedanken mit einer außenstehenden Person sprechen? Dann
      schreibe unseren psychologischen Berater*innen entweder über den Chat in
      der App oder vereinbare dort ein Telefonat mit ihnen.
    </p>
    <br />
    <p>
      Keine Sorge, unsere beratenden Psychologinnen und Psychologen unterliegen
      der gesetzlichen Schweigepflicht - somit werden alle Daten, die uns in
      diesem Rahmen erreichen, streng vertraulich behandelt und unter keinen
      Umständen an Krankenkassen, Deinen Arbeitgeber oder Drittanbieter
      weitergeleitet.
    </p>
  </>
)

const TeamSection = ({ children, appointmentScreen }) => {
  return (
    <Grid container component="section" direction="row" className="bounded-w">
      <Grid container item xs={12} md={6}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className="screenshot-container">
        <img
          className="screenshot"
          alt="contact"
          src={
            appointmentScreen
              ? "/assets/img/marketing/chat_call_reflection.jpg"
              : "/assets/img/marketing/chat_reflection.jpg"
          }
        />
      </Grid>
    </Grid>
  )
}

TeamSection.propTypes = {
  children: PropTypes.node,
  appointmentScreen: PropTypes.bool,
}

TeamSection.defaultProps = {
  children: defaultContent,
  appointmentScreen: true,
}

export default TeamSection
