import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import MindanceAppSection from "src/components/marketing/mindance-app-section"
import TeamSection from "src/components/marketing/team-section"
import GetStartedSection from "src/components/marketing/get-started-section"
import SignatureSection from "src/components/marketing/signature-section"
import EmailInput from "src/components/marketing/email-input"
import "src/styles/pages/marketing-page.scss"
import TitleStrip from "src/components/marketing/title-strip"
import SEO from "src/components/seo"

const REGISTRATION_CODE = process.env.GATSBY_DRK_REGISTRATION_CODE

export default function DRKLandingPage() {
  return (
    <Layout className="marketing-page-root">
      <TitleStrip
        logos={[
          "/assets/img/marketing/DRK-Logosystematik_Horizontal_KITAS_RGB-01@2x.png",
          "/assets/img/marketing/Group 1960@2x.png",
          "/assets/img/marketing/mindance_anmeldung.png",
        ]}
      />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title:
            "Endlich weniger Stress, besserer Schlaf und ein gesteigertes Wohlbefinden!",
          description:
            "Mitarbeitende des DRK Kreisverband Leipzig Land e.V. und Tochtergesellschaften erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen.",
        }}
      >
        <EmailInput
          short
          placeholder="E-Mail geschäftlich"
          registrationCode={REGISTRATION_CODE}
        />
      </Jumbotron>
      <SEO
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
        title="DRK Kreisverband Leipzig Land e.V."
        description="Mitarbeitende des DRK Kreisverband Leipzig Land e.V. und Tochtergesellschaften erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen."
      />
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h1>Stress reduzieren und Wohlbefinden steigern</h1>
          <p>
            Zeitdruck, Emotionsarbeit und Schichtdienste - die Arbeit beim DRK
            Kreisverband Leipzig-Land e.V. und Tochtergesellschaften kann neben
            vielen schönen Momenten auch für Erschöpfung, Frustration und Stress
            sorgen. Hinzu kommen in der Freizeit noch private Herausforderungen.
            Diese Belastungen führen auf Dauer zur Beeinträchtigung der
            körperlichen und psychischen Gesundheit - Burnout, Schlafstörungen,
            Rückenschmerzen, Herz-Kreislauf Probleme und Depressionen sind nur
            einige Beispiele für negative Folgen von Dauerbelastungen.
          </p>
          <br />
          <p>
            <b>
              Die gute Nachricht: Mit Mindance können Sie Stress reduzieren und
              Ihr Wohlbefinden nachhaltig steigern!
            </b>
          </p>
          <br />
          <h2 className="green-text">
            Kostenlos für alle Mitarbeitenden des DRK Kreisverband Leipzig-Land
            e.V. und Tochtergesellschaften
          </h2>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/marketing/mindfrinds_achtsamkeit_reflection.jpg"
          />
        </Grid>
      </Grid>
      <MindanceAppSection />
      <TeamSection />
      <GetStartedSection
        emailComponent={
          <EmailInput
            placeholder="E-Mail Adresse"
            registrationCode={REGISTRATION_CODE}
          />
        }
      />
      <SignatureSection />
    </Layout>
  )
}
